
const SET_COUNTRIES = 'SET_COUNTRIES',
    SET_ACTIVITIES = 'SET_ACTIVITIES',
    SET_COUNTRY_DETAIL = 'SET_COUNTRY_DETAIL',
    CREATE_ACTIVITY = 'CREATE_ACTIVITY',

    ORDER_COUNTRIES_BY_NAME_ASC = 'ORDER_COUNTRIES_BY_NAME_ASC',
    ORDER_COUNTRIES_BY_NAME_DESC = 'ORDER_COUNTRIES_BY_NAME_DESC',

    ORDER_COUNTRIES_BY_POPULATION_ASC = 'ORDER_COUNTRIES_BY_POPULATION_ASC',
    ORDER_COUNTRIES_BY_POPULATION_DESC = 'ORDER_COUNTRIES_BY_POPULATION_DESC',

    FILTER_CONTINENT = 'FILTER_CONTINENT',
    FILTER_ACTIVITY = 'FILTER_ACTIVITY',

    IS_LOADING = 'IS_LOADING'

export { SET_COUNTRIES, SET_COUNTRY_DETAIL, ORDER_COUNTRIES_BY_NAME_ASC, ORDER_COUNTRIES_BY_NAME_DESC, FILTER_CONTINENT, FILTER_ACTIVITY, IS_LOADING, ORDER_COUNTRIES_BY_POPULATION_ASC, ORDER_COUNTRIES_BY_POPULATION_DESC, CREATE_ACTIVITY, SET_ACTIVITIES }
